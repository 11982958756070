function consult() {

    $j('.md-close, .md-bg').click(function(){
        $j('.modal').fadeOut(300);
        setTimeout(function(){
            $j('.modal .inner').fadeOut(300);
        },500);

    });


    $j(document).bind("keyup", function (event) {
        if ( event.keyCode == 27 && !! $j('.modal').find('.md-close').length ) {
            $j('.modal').find('.md-close').trigger('click');
        }
    });

}
